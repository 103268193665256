import React from "react"
import { Helmet } from "react-helmet"
import { Container, Box } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import type { Props } from "@app/pages/returns-portal"
import type { PageProps } from "@root/types/global"
import Title from "@app/components/Sections/Title/Title"
import { css } from "@emotion/react"

const Returns: React.FC<PageProps<Props>> = ({ page }) => {
  const content = useContent(page)

  const iframeDefaultStyles = css`
    height: 80vh;
    max-height: 560px;
    width: 100%;
    border: none;
    @media (max-width: 767px) {
      max-height: 480px;
    }
  `
  return (
    <>
      <Title title={page?.title} />
      <Container maxW="container.md" pb={16} px={[6, 4]}>
        <Box as="section" pb={8} textAlign="center">
          {content}
        </Box>
        <Helmet>
          <script type="text/javascript" src="https://cdn.jsdelivr.net/gh/PostCo/embed-iframe/iframe-listener.min.js"></script>
        </Helmet>
        <Box as="section" pb={8} textAlign="center">
          <iframe id="postco360-iframe" css={iframeDefaultStyles} src="https://360.postco.co/returns-portal?embed=true"></iframe>
        </Box>
      </Container>
    </>
  )
}

export default Returns
